
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import VueMarkdown from "vue-markdown-render";
import _ from "lodash";
import helpers from "@/helpers/global";
export default defineComponent({
  name: "InterviewGuide",
  components: {
    VueMarkdown,
  },
  watch: {
    result(value) {
      if (value) {
        if (this.$refs.markdown && (this.$refs.markdown as any).$el) {
          const $el = (this.$refs.markdown as any).$el;
          // console.log($el.offsetTop, "$el.offsetTop", $el.clientHeight, "$el.clientHeight");
          // $el.scrollIntoView({ behavior: "smooth" });

          const top = $el.offsetTop + $el.clientHeight;
          window.scrollTo({
            top: top,
            behavior: "smooth",
          });
        }
      }
    },
  },
  data() {
    return {
      roleSelected: "",
      levelSelected: "",
      typeOfOrgSelected: "",
      coreValuesSelected: [] as string[],
      errors: {
        roleSelected: false,
        levelSelected: false,
        typeOfOrgSelected: false,
        coreValuesSelected: false,
      },
    };
  },
  computed: {
    canUseCredits(): boolean {
      return this.$store.getters["quota/canUseCredits"];
    },
    user(): any {
      return this.$store.getters["auth/user"] || {};
    },
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl: string =
        this.$store.getters["user/companyLogoUrl"] || "";
      const userDataEncoded: any = localStorage.getItem("userData");
      if (userDataEncoded) {
        try {
          const userData = JSON.parse(atob(userDataEncoded) || "{}");
          if (userData.email) {
            return companyLogoUrl;
          } else {
            return defaultLogoUrl;
          }
        } catch (e: any) {
          console.error(e);
        }
      } else {
        return defaultLogoUrl;
      }
      return "";
    },
    quota(): any {
      return this.$store.getters["quota/data"];
    },
    // @see views/Assessments/Create/SetupValues&Traits.vue
    valuesAndTraits() {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
    loading() {
      return this.$store.getters["interviewGuide/loading"];
    },
    roleOptions() {
      return this.$store.getters["toolbox/roleOptions"];
    },
    levelOptions() {
      return this.$store.getters["toolbox/levelOptions"];
    },
    typeOfOrgOptions() {
      return this.$store.getters["toolbox/typeOfOrgOptions"];
    },
    error() {
      return this.$store.getters["interviewGuide/error"];
    },
    result() {
      return this.$store.getters["interviewGuide/result"];
    },
    title() {
      return this.$store.getters["interviewGuide/title"];
    },
    subtitle() {
      return this.$store.getters["interviewGuide/subtitle"];
    },
    isTyping(): boolean {
      return this.$store.getters["interviewGuide/isTyping"];
    },
    locked(): boolean {
      return this.$store.getters["interviewGuide/locked"];
    },
  },
  async created() {
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });

    this.initValues();

    // @fixed test data
    /*
    this.roleSelected = "Software Engineer";
    this.levelSelected = "Lead";
    this.typeOfOrgSelected = "Technology Startup";
    this.coreValuesSelected = [
      "Courage to fail",
      "Initiative-making",
      "Responsiveness",
      "Excellence",
    ];
    //*/

    // TODO: Fix visibility issue
    setTimeout(() => {
      document.body.style.visibility = "visible";
    }, 200);

    let locale = "en";
    const lang =
      this.$router.currentRoute.value.meta.lang || localStorage.getItem("lang");
    if (lang && lang == "TH") {
      locale = "th";
    } else if (lang && lang == "EN") {
      locale = "en";
    }
    (this as any).$i18n.setLocale(locale);
    this.$store.commit("assessment/locale", locale, { root: true });
  },
  async mounted() {
    await this.$store.dispatch(
      "assessmentSection/loadSetupValuesAndTraits",
      null,
      {
        root: true,
      }
    );
    await this.checkCredits();
  },
  methods: {
    async initValues() {
      const USE_TEAM_VALUES = true;
      const user = this.$store.getters["user/user"] || {};
      if (!user) {
        console.error("User not found");
        return;
      }

      if (USE_TEAM_VALUES) {
        if (user && user.teamId) {
          await this.$store.dispatch(
            "team/getTeam",
            { teamId: user.teamId },
            { root: true }
          );
          const team = this.$store.getters["team/team"];
          if (team && !team.personal) {
            this.typeOfOrgSelected = team.typeOfOrg || "";
            this.coreValuesSelected = team.coreValues || [];
          } else {
            this.typeOfOrgSelected = user.typeOfOrg || "";
            this.coreValuesSelected = user.coreValues || [];
          }
        }
      } else {
        this.typeOfOrgSelected = user.typeOfOrg || "";
        this.coreValuesSelected = user.coreValues || [];
      }
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
    validate(): boolean {
      let error = true;
      this.errors.roleSelected = false;
      this.errors.levelSelected = false;
      this.errors.typeOfOrgSelected = false;
      this.errors.coreValuesSelected = false;

      if (!this.roleSelected.length) {
        this.errors.roleSelected = true;
        error = false;
      }
      if (!this.levelSelected.length) {
        this.errors.levelSelected = true;
        error = false;
      }
      if (!this.typeOfOrgSelected.length) {
        this.errors.typeOfOrgSelected = true;
        error = false;
      }
      if (!this.coreValuesSelected.length) {
        this.errors.coreValuesSelected = true;
        error = false;
      }
      return error;
    },
    async checkCredits() {
      // realtime user data
      const teamId = this.$store.getters["user/user"]
        ? this.$store.getters["user/user"].teamId
        : null;
      await this.$store.dispatch(
        "quota/canUseCredits",
        { teamId, credits: 1 },
        { root: true }
      );
    },
    async generate() {
      if (!this.canUseCredits) {
        // console.log("Not enough quota");
        return false;
      }
      if (this.loading || this.isTyping) return false;
      if (!this.validate()) {
        // @todo error
        return false;
      }
      await this.$store.dispatch(
        "interviewGuide/generate",
        {
          role: this.roleSelected,
          level: this.levelSelected,
          typeOfOrg: this.typeOfOrgSelected,
          coreValues: this.coreValuesSelected,
        },
        { root: true }
      );
      this.checkCredits();
    },
    clear() {
      if (this.loading || this.isTyping) return false;
      this.roleSelected = "";
      this.levelSelected = "";
      this.typeOfOrgSelected = "";
      this.coreValuesSelected = [];
    },
    renderResult(text: string) {
      text = text.replace(/([^>])\n/g, "$1<br /><br />\n");
      text = text.replace(/\*\*(.+)\*\*/g, "<b>$1</b>");
      text = text.replace(/(Note:)/g, "<b>$1</b>");
      return text;
    },
    renderCredits(
      quota: { unlimited: boolean; quota_remaining: number } | null
    ) {
      if (_.isObject(quota)) {
        if (quota.unlimited) {
          return helpers.t("myAccount.Unlimited");
        } else {
          return (
            String(quota.quota_remaining) +
            " " +
            helpers.t(
              quota.quota_remaining > 1
                ? "myAccount.credits"
                : "myAccount.credit"
            )
          );
        }
      }
      return "";
    },
    copyText() {
      if (this.isTyping) return;
      const value = this.result;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(value);
      ElMessage({
        message: (this as any).$t("popup.message.message_8"),
        type: "success",
      });
    },
  },
});
